.copyright {
  background-color: #247774;
  padding: 25px;
  p {
    margin-bottom: 0 !important;
    text-align: center;
    color: #92b4b2;
  }
}
.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
