@font-face {
  font-family: "AvenirNextCyr-Bold";
  src: url("../../assets/fonts/avenirnextcyr-bold.eot");
  src: url("../../assets/fonts/avenirnextcyr-bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/avenirnextcyr-bold.woff2") format("woff2"),
    url("../../assets/fonts/avenirnextcyr-bold.woff") format("woff"),
    url("../../assets/fonts/avenirnextcyr-bold.ttf") format("truetype"),
    url("../../assets/fonts/avenirnextcyr-bold.svg#avenirnextcyr-bold")
      format("svg"); /* Chrome < 4, Legacy iOS */
}
html,
body {
  overflow-x: hidden;
  height: 100%;
  box-sizing: border-box;
}

.container {
  max-width: 1520px !important;
}

.main {
  // font-family: "AvenirNextCyr-Bold" !important;
  background-color: #12837b !important;
  // min-width: 100vh;
  // background-attachment: fixed;
  background-image: url("../../assets/img/bolakay_bg.png");
  background-size: 43%;
  background-repeat: no-repeat;
  background-position: right 10% top 100% !important;
  min-height: 100vh;
}
.Nav {
  width: 28%;
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.2);
  background-color: #cee4e3 !important;
  border-bottom-right-radius: 95px;
  img {
    width: 100%;

    padding: 28px 85px;
  }
}
.img-bg {
  width: 64%;
  position: absolute;
  top: 0;
  right: 0;
}
.main-text {
  margin-top: 70px;
  h1 {
    font-family: "AvenirNextCyr-Bold" !important;
    color: #fff;
    font-size: 120px;
    margin-top: 120px;
  }
  .main-title {
    margin-top: -27px;
  }
  .firs_span_title {
    font-family: "AvenirNextCyr-Bold" !important;
    color: #fff005;
    font-weight: bold;
    font-size: 30px;
  }
  .second_span_title {
    font-family: "AvenirNextCyr-Bold" !important;
    color: #fff005;
    font-weight: bold;
    font-size: 60px;
  }
}
.admission_text {
  font-family: "AvenirNextCyr-Bold" !important;
  color: #fff;
  font-size: 100px;
  margin-top: 120px;
}
.btn_group {
  background-color: #cee4e3 !important;
  color: #097971 !important;
  font-weight: bold !important;
  margin-right: 20px !important;
  font-size: 20px !important;
  text-decoration: none;
  .btn_group_btn {
    background-color: #cee4e3 !important;
    color: #097971 !important;
    font-weight: bold !important;
    font-size: 20px !important;
    text-decoration: none;
  }
}
.buttons {
  margin-top: 20px;
}
.call_center_all {
  .call_center {
    font-weight: 200;
    font-family: "AvenirNextCyr-Bold" !important;
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
  }
  p {
    font-weight: bold;
    font-family: "AvenirNextCyr-Bold" !important;
    font-size: 20px;
    color: #fff;
    margin-bottom: 0;
  }
  margin-top: 260px;
  margin-bottom: 32px;
}

@media only screen and (max-width: 800px) {
  .Nav {
    width: 57%;
  }
  .Nav img {
    width: 99%;
    padding: 13px 41px 14px 11px;
  }
  .main-text h1 {
    font-size: 53px;
    margin-top: 0 !important;
  }
  .main-text .firs_span_title {
    font-size: 15px;
  }
  .main-text .second_span_title {
    font-size: 18px;
  }
  .main-text .main-title {
    margin-top: -13px;
  }
  .buttons {
    text-align: center;
    display: grid;
    grid-row-gap: 10px;
    grid-template-columns: 117px;
    justify-content: center;
  }
  .btn_group .btn_group_btn {
    font-size: 12px !important;
  }
  .main {
    background-position: right 0% top 100% !important;
    background-size: 75%;
    min-height: 100vh !important;
  }
  .call_center_all {
    margin-top: 68px;
    margin-bottom: 177px;
  }

  .main-text {
    margin-top: 28px;
  }
}
