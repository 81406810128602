.brand_logo {
  background-color: #cee4e3;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2);
  img {
    width: 13%;
  }
}
.navbar-brand {
  width: 86% !important;
  margin-right: 0 !important;
}
.call_center_all {
  .call_center {
    font-weight: 200;
    font-family: "AvenirNextCyr-Bold" !important;
    font-size: 16px;
    color: #fff;
  }
  p {
    font-weight: bold;
    font-family: "AvenirNextCyr-Bold" !important;
    font-size: 20px;
    color: #fff;
  }
}

.call_center_all_second {
  .call_center {
    font-weight: 200;
    font-family: "AvenirNextCyr-Bold" !important;
    font-size: 16px;
    color: #006460;
    margin-bottom: 0;
  }
  p {
    font-weight: bold;
    font-family: "AvenirNextCyr-Bold" !important;
    font-size: 20px;
    color: #006460;
    margin-bottom: 0;
  }
}
